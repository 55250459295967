import {
  apiStage,
  desarquivadosAppApiKey,
  desarquivadosPaymentsApiKey,
  desarquivadosAppApiUrl,
  desarquivadosPaymentsApiUrl,
} from "./urls";

const getHeaders = (payments = false) => {
  const HEADERS = {
    "Content-Type": "application/json",
    "X-Api-Key": payments
      ? desarquivadosPaymentsApiKey || ""
      : desarquivadosAppApiKey || "",
    Accept: "application/json",
  };
  return HEADERS;
};

const getData = async (
  endpoint: string,
  params: Record<string, string> = {},
  payments = false
) => {
  const url = new URL(
    `${payments ? desarquivadosPaymentsApiUrl : desarquivadosAppApiUrl}/${apiStage}/${endpoint}`
  );
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key])
  );

  const response = await fetch(url, {
    method: "GET",
    headers: getHeaders(payments),
  });
  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.error || "Network response was not ok");
  }
  return response.json();
};

const postData = async (
  endpoint: string,
  params: Record<string, string> = {},
  body: Record<string, any> = {},
  payments = false
) => {
  const url = new URL(
    `${payments ? desarquivadosPaymentsApiUrl : desarquivadosAppApiUrl}/${apiStage}/${endpoint}`
  );
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key])
  );

  const response = await fetch(url, {
    method: "POST",
    headers: getHeaders(payments),
    body: JSON.stringify(body),
  });
  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.error || "Network response was not ok");
  }
  return response.json();
};

const deleteData = async (
  endpoint: string,
  params: Record<string, string> = {}
) => {
  const url = new URL(`${desarquivadosAppApiUrl}/${apiStage}/${endpoint}`);
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key])
  );

  const response = await fetch(url, {
    method: "DELETE",
    headers: getHeaders(),
  });
  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.error || "Network response was not ok");
  }
  return response.json();
};

export { getData, postData, deleteData };
