import {
  BoldMediumText,
  DividerSection,
  MainButton,
  MediumText,
  TitleLargeText,
  TitleMediumText,
} from "../../../utils/GlobalStyles";
import {
  CharacterProps,
  GameFinishedProps,
} from "../../../utils/Interfaces/Game";
import GameHeader from "../CaseGamePage/GameHeader";
import { CaseGamePageMain } from "../CaseGamePage/styles";
import {
  ActionButtonsBox,
  GameFinishedMainContentWrapper,
  JudgeLetterBox,
  StyledCharactersSlider,
} from "./styles";
import CaseReview from "../../CaseReview";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import ArrowCircleLeftRoundedIcon from "@mui/icons-material/ArrowCircleLeftRounded";
import ReviewsRoundedIcon from "@mui/icons-material/ReviewsRounded";
import VerifiedUserRoundedIcon from "@mui/icons-material/VerifiedUserRounded";
import CharacterStoryCard from "../../CharacterStoryCard";
import { GeneralAppDataContext } from "../../../utils/GeneralContext";
import { GeneralAppDataContextType } from "../../../utils/Interfaces/Global";
import { addTextToPdf } from "../../../utils/Functions/GenerateCertificate";
import React, { useEffect } from "react";
import { PALETTE } from "../../../assets/theme";

export default function GameFinished(props: GameFinishedProps) {
  const { user } = React.useContext(
    GeneralAppDataContext
  ) as GeneralAppDataContextType;
  const { caseData, characters } = props;
  const [sortedCharacters, setSortedCharacters] = React.useState<
    CharacterProps[]
  >([]);
  const [reviewModalOpen, setReviewModalOpen] = React.useState(false);
  const guiltyName = caseData?.characters.filter(
    (character: CharacterProps) => character["guiltStatus"] === 1
  )[0]["name"];

  useEffect(() => {
    const sortedData = characters?.sort((a, b) => {
      if (a.guiltStatus === -1 && b.guiltStatus !== -1) {
        return -1;
      }
      if (a.guiltStatus !== -1 && b.guiltStatus === -1) {
        return 1;
      }
      if (a.guiltStatus !== -1 && b.guiltStatus !== -1) {
        return a.name.localeCompare(b.name);
      }
      return 0;
    });
    if (sortedData) {
      setSortedCharacters(sortedData);
    }
  }, [characters]);

  const handleReviewModalOpen = () => setReviewModalOpen(!reviewModalOpen);

  const SamplePrevArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <div onClick={onClick} className={`arrow ${className}`}>
        <ArrowCircleLeftRoundedIcon
          className="arrows"
          style={{
            color: PALETTE.secondaryFour,
            width: "35px",
            height: "35px",
          }}
        />
      </div>
    );
  };

  const SampleNextArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <div onClick={onClick} className={`arrow ${className}`}>
        <ArrowCircleRightRoundedIcon
          className="arrows"
          style={{
            color: PALETTE.secondaryFour,
            width: "35px",
            height: "35px",
          }}
        />
      </div>
    );
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    className: "slides",
    nextArrow: <SampleNextArrow to="next" />,
    prevArrow: <SamplePrevArrow to="prev" />,
  };

  return (
    <>
      <CaseGamePageMain>
        <GameHeader
          caseData={caseData}
          handleModalOpen={handleReviewModalOpen}
          finished
        />
        <GameFinishedMainContentWrapper>
          <TitleMediumText mx="auto" mt={4}>
            Parabéns, Detetive {user?.lastName}!
          </TitleMediumText>
          <DividerSection />
          <BoldMediumText>
            Despacho emitido pelo Meretíssimo Juiz de Instrução Criminal da
            Comarca de Lisboa, Silvério Menezes, sobre o caso{" "}
            {caseData?.caseNumber}
          </BoldMediumText>
          <JudgeLetterBox mt={2}>
            <MediumText>A/C Rogério Flores, </MediumText>
            <MediumText>
              Coordenador de investigação criminal da Polícia de Portugal
            </MediumText>
            <MediumText mt={2.5}>
              Após analisar o caso {caseData?.caseNumber} apresentado pelo
              Departamento de Investigação Criminal da Polícia de Portugal, as
              diferentes provas reunidas e tendo em consideração as conclusões
              retiradas pelos investigadores CONSIDERO:
            </MediumText>
            <MediumText mt={1}>
              Existem inidicios suficientes para que o suspeito {guiltyName}{" "}
              seja considerado arguido no caso {caseData?.caseNumber} e
              formalmente acusado e julgado num tribunal penal.
            </MediumText>
            <MediumText mt={1}>
              O julgamento será marcado num prazo de 90 dias num tribunal da
              comarca de Lisboa.
            </MediumText>
            <MediumText mt={2.5} textAlign="right">
              Silvério Menezes,
            </MediumText>
            <MediumText textAlign="right">
              Juíz de Instrução Criminal da Comarca de Lisboa
            </MediumText>
          </JudgeLetterBox>
          <DividerSection />
          <BoldMediumText mt={3}>
            Obrigado pela tua ajuda. Graças à tua investigação foi feita justiça
            e temos mais um caso DESARQUIVADO com sucesso!
          </BoldMediumText>
          <BoldMediumText mt={1}>
            O Coordenador Rogério Flores ficou impressionado com o teu trabalho
            e espera poder contar com o teu apoio para DESARQUIVAR mais casos.
          </BoldMediumText>
          <BoldMediumText mt={1}>
            Descarrega o certificado de conclusão emitido pela Polícia de
            Portugal e partilha o teu sucesso nas tuas redes sociais!
          </BoldMediumText>
          <ActionButtonsBox my={6}>
            <MainButton
              onClick={() =>
                addTextToPdf(
                  caseData?.path!,
                  `${user?.firstName} ${user?.lastName}`
                )
              }
            >
              <VerifiedUserRoundedIcon
                style={{ width: "20px", height: "20px" }}
              />
              <BoldMediumText>Vê o teu certificado</BoldMediumText>
            </MainButton>
            <MainButton onClick={handleReviewModalOpen}>
              <ReviewsRoundedIcon style={{ width: "20px", height: "20px" }} />
              <BoldMediumText>Fazer review</BoldMediumText>
            </MainButton>
          </ActionButtonsBox>
          <TitleMediumText mb={5}>O resumo do caso</TitleMediumText>
          <StyledCharactersSlider {...settings}>
            {sortedCharacters?.map((character: CharacterProps) => (
              <CharacterStoryCard
                imageUrl={caseData?.imageUrl}
                character={character}
              />
            ))}
          </StyledCharactersSlider>
        </GameFinishedMainContentWrapper>
      </CaseGamePageMain>
      <CaseReview
        caseId={caseData?.caseId}
        caseReviewModal={reviewModalOpen}
        setCaseReviewModal={setReviewModalOpen}
        handleModalClose={handleReviewModalOpen}
      />
    </>
  );
}
